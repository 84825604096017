import { blog as blogTypes, comments as commentsTypes } from '@codesass/types';
import { comments as api } from '@codesass/api';

import { useCallback } from 'react';

import Discussion from 'modules/comments/components/Discussion';

type ArticleDiscussionProps = {
  slug: blogTypes.Article['slug'];
};

const ArticleDiscussion = ({ slug }: ArticleDiscussionProps) => {
  const saveComment = useCallback(
    ({ content }: Pick<commentsTypes.Comment, 'content'>) => {
      api.addComment({
        type: 'article',
        slug,
        content,
      });
    },
    [slug]
  );

  const loadDiscussion = useCallback(async () => {
    return api.getComments({
      type: 'article',
      slug,
    });
  }, [slug]);

  return (
    <Discussion
      saveBtnTitle="เพิ่มคำถาม"
      noDiscussionText="ไม่พบคำถาม / คำตอบ"
      onSave={saveComment}
      onLoad={loadDiscussion}
    ></Discussion>
  );
};

export default ArticleDiscussion;
