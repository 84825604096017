import { graphql, PageProps } from 'gatsby';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';

import { ui as types } from '@codesass/types';

import SEO from 'modules/ui/components/SEO';

import ArticleDetails from '../components/ArticleDetails';

export type ArticleTemplateData = {
  mdx: {
    slug: string;
    body: string;
    tableOfContents: types.TableOfContents;
    frontmatter: {
      title: string;
      excerpt: string;
      tags: string[];
      image: FileNode;
      updatedAt: string;
    };
  };
};

const ArticleTemplate = ({
  data: {
    mdx: { slug, body, frontmatter, tableOfContents },
  },
}: PageProps<ArticleTemplateData>) => {
  const article = {
    slug,
    body,
    tableOfContents,
    ...frontmatter,
  };

  return <ArticleDetails article={article} />;
};

export const query = graphql`
  query ArticleQuery($slug: String!) {
    mdx(slug: { eq: $slug }) {
      slug
      body
      tableOfContents
      frontmatter {
        title
        excerpt
        tags
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              width: 1460
              height: 830
              layout: FIXED
            )
          }
        }
        updatedAt(locale: "th", formatString: "DD MMM YYYY")
      }
    }
  }
`;

export const Head = ({
  data: {
    mdx: {
      slug,
      frontmatter: { title, excerpt, image },
    },
  },
}: PageProps<ArticleTemplateData>) => {
  return (
    <SEO
      title={title}
      path={`/blog/articles/${slug}`}
      type="article"
      coverImage={image}
      desc={excerpt}
    ></SEO>
  );
};

export default ArticleTemplate;
