import { graphql, useStaticQuery } from 'gatsby';
import { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import { useMemo } from 'react';

type SEOProps = {
  path: string;
  title: string;
  type?: 'article' | 'course';
  desc: string;
  coverImage?: FileNode;
};

const SEO = ({ path, title, type, desc, coverImage }: SEOProps) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
    logo,
    allImageSharp,
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      logo: imageSharp(original: { src: { regex: "/logo/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      allImageSharp(
        filter: { fixed: { src: { regex: "/normal-image.png$/" } } }
      ) {
        edges {
          node {
            fixed(height: 415, width: 730) {
              src
              height
              width
            }
          }
        }
      }
    }
  `);

  const image = useMemo(() => {
    if (coverImage) {
      const {
        images: { fallback: { src: coverImageURL } = {} },
        width: coverImageWidth,
        height: coverImageHeight,
      } = coverImage.childImageSharp!.gatsbyImageData;

      return { coverImageURL, coverImageWidth, coverImageHeight };
    }

    return {};
  }, [coverImage]);

  const imageMeta = useMemo(() => {
    if (coverImage) {
      const { coverImageURL, coverImageWidth, coverImageHeight } = image;

      return (
        <>
          <meta
            property="og:image:width"
            content={coverImageWidth?.toString()}
          />
          <meta
            property="og:image:height"
            content={coverImageHeight?.toString()}
          />
          <meta property="og:image" content={`${siteUrl}${coverImageURL}`} />
        </>
      );
    } else {
      const { src, height, width } = allImageSharp.edges[0].node.fixed;

      return (
        <>
          <meta property="og:image:width" content={width} />
          <meta property="og:image:height" content={height} />
          <meta property="og:image" content={`${siteUrl}${src}`} />
        </>
      );
    }
  }, [allImageSharp.edges, coverImage, image, siteUrl]);

  const schema = useMemo(
    () => ({
      '@context': 'http://schema.org',
      '@graph': [
        type === 'article'
          ? {
              '@type': 'Course',
              name: title,
              description: desc,
              provider: {
                '@id': 'https://www.codesass.com/#codesassOrganization',
              },
            }
          : {
              '@type': 'TechArticle',
              headline: title,
              image: `${siteUrl}${image.coverImageURL}`,
              url: `${siteUrl}${path}`,
              description: desc,
            },
        {
          '@id': 'https://www.codesass.com/#codesassOrganization',
          '@type': 'Organization',
          name: 'โค้ดศาสตร์',
          description:
            'ศาสตร์การเขียนโค้ดเริ่มต้นที่นี่ เราจึงคัดสรรคอร์สที่เหมาะสมกับผู้เรียนทุกระดับ เพื่ออัพสกิลสู่การเป็นโปรแกรมเมอร์สกิลเทพ',
          url: 'https://www.codesass.com',
          logo: `${siteUrl}${logo.fixed.src}`,
          contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'customer service',
            email: 'codesass@hotmail.com',
            availableLanguage: ['Thai'],
          },
          sameAs: [
            'https://www.facebook.com/codesass/',
            'https://www.twitter.com/codesass/',
            'https://www.instagram.com/code.sass/',
          ],
        },
      ],
    }),
    [desc, image.coverImageURL, logo.fixed.src, path, siteUrl, title, type]
  );

  return (
    <>
      <meta property="og:site_name" content="โค้ดศาสตร์" />
      <meta property="og:locale" content="th_TH" />
      <title>{title}</title>
      <meta property="og:url" content={`${siteUrl}${path}`} />
      {type && <meta property="og:type" content="article" />}
      <meta property="og:title" content={title} />
      <link rel="canonical" href={`${siteUrl}${path}`} />
      <meta name="description" content={desc} />
      <meta property="og:description" content={desc} />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      {imageMeta}
    </>
  );
};

export default SEO;
