import { useDispatch } from 'react-redux';

import { Typography, Container, Divider } from '@mui/material';

import { useEffect } from 'react';
import { blog as types } from '@codesass/types';

import {
  MDXRenderer,
  LeftSidebar,
  TableOfContents,
} from 'modules/ui/components/shared';

import { clearCurrentTocId } from 'modules/ui/slice';

import fbTrack from 'modules/shared/helpers/fbTrack';

import ArticleDiscussion from './ArticleDiscussion';

type ArticleDetailsProps = {
  article: types.ArticleDetails;
};

const ArticleDetails = ({ article }: ArticleDetailsProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCurrentTocId());
  }, [article, dispatch]);

  useEffect(() => {
    fbTrack('track', 'ViewContent', {
      content_type: 'article',
      content_ids: [article.slug],
      content_name: article.title,
      content_category: article.tags,
    });
  }, [article.slug, article.tags, article.title]);

  if (!article) return null;

  return (
    <>
      <Container maxWidth="md">
        <article>
          <header>
            <Typography
              gutterBottom
              variant="h4"
              component="h1"
              align="center"
              sx={{ mt: 2 }}
            >
              {article.title}
            </Typography>
          </header>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ fontSize: '1.25rem', color: 'grey.800' }}
          >
            <MDXRenderer>{article.body}</MDXRenderer>
          </Typography>
        </article>
        <Divider sx={{ mb: 2 }}></Divider>
        <ArticleDiscussion slug={article.slug}></ArticleDiscussion>
      </Container>
      <LeftSidebar.RendererSource>
        <TableOfContents toc={article.tableOfContents.items}></TableOfContents>
      </LeftSidebar.RendererSource>
    </>
  );
};

export default ArticleDetails;
